import React from 'react'

import { Hero } from '../../components/blog/hero'
import { PostSidebar } from '../../components/blog/post-sidebar'
import { Breadcrumbs, BreadcrumbsItem } from '../../components/breadcrumbs'
import { Container } from '../../components/container'
import SEO from '../../components/seo'
import img1 from './images/shutterstock_1049157986.jpg'
import styles from './post-grid.module.css'

const CoffeeBattleWhichCoffeeIsHealthier = () => {
  return (
    <div className={styles.wrapper}>
      <SEO
        title={'Kávový souboj - která káva je zdravější?'}
        description={
          'Máte rádi kávu? O tom, jaké má káva benefity a rizika už jste dozajista slyšeli. Zamysleli jste se ale někdy nad tím, jaký je (nutriční) rozdíl mezi tím, když si dáte espresso, lattéčko nebo ochucené frappuccino?'
        }
      />
      <Container>
        <Breadcrumbs style={{ margin: '24px 0' }}>
          <BreadcrumbsItem link="/">Domu</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/posts">Blog</BreadcrumbsItem>
          <BreadcrumbsItem link="/blog/coffee-battle-which-coffee-is-healthier">
            Kávový souboj - která káva je zdravější?
          </BreadcrumbsItem>
        </Breadcrumbs>
      </Container>
      <Container>
        <div className={styles.postGrid}>
          <div>
            <Hero
              title="Kávový souboj - která káva je zdravější?"
              date="05.06.2023"
            />
            <div>
              <p className={styles.postText}>
                Máte rádi kávu? O tom, jaké má káva benefity a rizika už jste dozajista slyšeli. Zamysleli jste se ale někdy nad tím, <b>jaký je (nutriční) rozdíl</b> mezi tím, když si dáte espresso, lattéčko nebo ochucené frappuccino?
              </p>

              <img
                src={img1}
                className={styles.postImg}
                style={{ marginBottom: '40px' }}
                alt="jídlo v krabicích"
              />

              <p className={styles.postText}>Podívejme se na <b>nutriční hodnoty jednotlivých nápojů</b>. Předpokládejme, že espresso ani káva s mlékem nejsou oslazeny. Hodnoty jsou orientační a u některých nápojů se mohou lišit, ale pro představu:
              </p>

              <table>
                <thead>
                <tr>
                  <th></th>
                  <th>Espresso 30 ml</th>
                  <th>Caffè latte 300 ml (30 ml kávy, 270 ml mléka)</th>
                  <th>Karamelové frappuccino 350 ml (nejmenší porce)</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>Energetická hodnota (kcal)</td>
                  <td>1</td>
                  <td>75</td>
                  <td><span className={styles.coloredSpan}>260</span></td>
                </tr>
                <tr>
                  <td>Bílkoviny (g)</td>
                  <td>0,06</td>
                  <td><span className={styles.coloredSpan}>5</span></td>
                  <td>3</td>
                </tr>
                <tr>
                  <td>Sacharidy (g)</td>
                  <td>0</td>
                  <td>7</td>
                  <td>38</td>
                </tr>
                <tr>
                  <td>Z toho cukry (g)</td>
                  <td>0</td>
                  <td>7</td>
                  <td><span className={styles.coloredSpan}>37</span></td>
                </tr>
                <tr>
                  <td>Tuky (g)</td>
                  <td>0,12</td>
                  <td>3</td>
                  <td><span className={styles.coloredSpan}>11</span></td>
                </tr>
                <tr>
                  <td>Kofein (mg)</td>
                  <td><span className={styles.coloredSpan}>60</span></td>
                  <td><span className={styles.coloredSpan}>60</span></td>
                  <td><span className={styles.coloredSpan}>60</span></td>
                </tr>
                </tbody>
              </table>


              <p className={styles.postText}>
                Začněme s klasickou <b>černou kávou</b>. Vidíte, že espresso neobsahuje <b>téměř žádné kalorie</b>, a vlastně ani žádné jiné živiny. Obsahuje však cca <b>60 mg kofeinu</b>, což je látka, která <b>stimuluje centrální nervovou soustavu</b>. Zvyšuje bdělost a snižuje pocit únavy, je proto vhodnější černou kávu konzumovat <b>spíše v první polovině dne</b>. Obsah kofeinu mají společný všechny kávy, pokud se dělají z jednoho “shotu”, tedy z jednoho espressa. Výhodou černé kávy je například <b> rychlost vstřebání kofeinu</b> do krevního oběhu a téměř žádná kalorická hodnota.
              </p>

              <p className={styles.postText}>Pokud k espressu přidáme určitý objem mléka, v tomto případě 270 ml, vznikne nápoj s o něco vyšší energetickou hodnotou. Jak můžete vidět, cca 270 ml mléka obsahuje zhruba 5 gramů bílkovin, pokud jste si tedy myslely, že je mléko <b>významným zdrojem bílkovin</b>, musím vás zklamat. Na druhou stranu je mléko <b>velmi dobrým zdrojem vápníku</b>. To se však nedá říct o mléku, které je přidáno do kávy. Vápník v mléce totiž tvoří s látkami, které se nachází v kávě, <b>nestravitelný komplex</b>, proto jej do <b>denního příjmu vápníku nelze počítat.</b> Mléko v kávě má však své výhody. Například
                 <b> snižuje kyselost kávy</b>, takže je nápoj <b>šetrnější k vašemu žaludku</b>. Přidání mléka do kávy také <b>snižuje rychlost vstřebávání kofeinu</b> do krevního oběhu, což vede k jeho mírnějším účinkům. To sice nemusí být zrovna to, co od kávy očekáváte, ale pokud si ji dáváte například v pozdějších odpoledních hodinách, je to určitě lepší varianta.
              </p>

              <p className={styles.postText}>Podívejme se ale na <b>ochucené frappuccino</b>, velmi oblíbenou kávovou variantu. Kalorická hodnota je skoro <b>3,5x vyšší</b>, než u neslazené kávy s mlékem. A to se jedná o úplně <b>nejmenší variantu</b>, kterou si můžete zvolit. Nejpozoruhodnější je asi <b>množství cukru</b>, které zde najdeme. Jedná se o 37 gramů, což je zhruba <b>6 kostek cukru</b>. Část cukru tvoří i přirozeně se vyskytující <b>laktóza</b>, což vysvětluje, proč se cukr nachází i v neslazené kávě s mlékem. Jen pro zajímavost, u největší varianty tohoto nápoje byste našli okolo 54 gramů cukru (720 ml). Také tuku je zde oproti zbylým druhům kávy dost, a to 11 gramů.
              </p>

              <p className={styles.postText}>I tato káva však může mít <b>místo ve zdravém jídelníčku</b>. Nicméně musíte <b>zohledit její kalorickou hodnotu a množství cukru</b> a nebrat jej jako nástroj pro zlepšení kognitivních funkcí, ale spíše jako <b>dobrotu</b>, kterou si čas od času dopřejete. 260 kcal je přeci jen <b>energetická hodnota jedné běžné svačiny</b>, která vašemu tělu dodá kromě kalorií i potřebné živiny, pokud je sestavena správně.
              </p>
              <p className={styles.postText}><b>Závěrem</b></p>

              <p className={styles.postText}>Po jaké kávě sáhnete záleží jen na vás a vašich preferencích. Každý druh má své <b>výhody i nevýhody</b> a ani o jednom se nedá říct, že by byl nezdravý. U jídla a nápojů <b>jde totiž o množství, ne o to, jestli má nálepku “zdravé” a “nezdravé”</b>. Sami se proto rozhodněte, kolik jaké kávy chcete pít a kdy. Černou kávu <b>není vhodné konzumovat v pozdějších odpoledních hodinách</b>, protože velké množství kofeinu může narušovat váš spánkový režim. Pokud jste na kofein citlivý, dávejte pozor i na kávu s mlékem. U ochucených káv si zase <b>dávejte pozor na kalorickou hodnotu a množství cukru</b>.</p>

              <p>Goditi il tuo caffè!</p>
            </div>
          </div>
          <PostSidebar />
        </div>
      </Container>
    </div>
  )
}

export default CoffeeBattleWhichCoffeeIsHealthier
